import React, { useState } from "react";

import Accordion from "~/components/child/ui/Accordion";
import { PairsSymbol } from "~/components/child/Symbols";
import { Node } from "~/components/child/editHistory/Node";

import bottomNavigationSvg from "~/assets/icon/arrows/bottomNavigation.svg";
import topNavigationSVG from "~/assets/icon/arrows/topNavigation.svg";

export const Cart = ({ pair, timeFrame, breakCount, breakPoints }) => {
  return (
    <Accordion
      className={{ root: "p-0 m-0" }}
      label={{
        leftPart: (
          <PairsSymbol pair={pair?.key} image={[pair.bade_image, pair.quote_asset]} />
        ),
        rightPart: (
          <div className="flex items-center text-xs font-robotoMed gap-1 text-storm-50">
            <span className="py-1.5 rounded-2xl px-2.5 bg-storm-20">{breakCount}</span>
            <span className="py-1.5 rounded-2xl px-2.5 bg-storm-20">{timeFrame}</span>
          </div>
        ),
      }}
      openDefault={false}
    >
      {breakPoints?.length > 0 && (
        <div
          className={`${
            breakPoints?.length !== 1 ? "node" : ""
          } flex-col gap-2 flex items-center relative w-full`}
        >
          {breakPoints?.map((node, index) => (
            <Node
              title={
                <span className={` flex font-roboto text-storm-80 text-sm`}>
                  {node.break_time}
                </span>
              }
              details={
                <span className="flex p-2 bg-sky-20 rounded-xl">
                  <img
                    src={
                      node?.break_type?.toLowerCase() === "down"
                        ? bottomNavigationSvg
                        : topNavigationSVG
                    }
                    alt="icon"
                    className=""
                  />
                </span>
              }
              uuid={index}
              isActive={false}
            />
          ))}
        </div>
      )}
    </Accordion>
  );
};

export const SecondStep = ({ vmcData, setVmcData, onSelectCard, isLoading }) => {
  const [selectedCarts, setSelectedCarts] = useState([]);

  const handleCartClick = (item, isSelected) => {
    if (!isSelected) {
      setSelectedCarts((prevState) => [
        ...prevState,
        { pair: item.pair.key, interval: item.time_frame },
      ]);
      onSelectCard(item.pair, item.time_frame);
    } else {
      setSelectedCarts((prevState) =>
        prevState.filter(
          (cart) => !(cart.pair === item.pair.key && cart.interval === item.time_frame)
        )
      );
      setVmcData((prevState) => ({
        ...prevState,
        charts: prevState.charts.filter((chart) => {
          return !(
            Object.keys(chart)[0] === item.pair.key &&
            Object.keys(Object.values(chart)[0])[0] === item.time_frame
          );
        }),
      }));
    }
  };
  return (
    <>
      <p className="text-xs text-oil-50">Select up to 4 to display the chart</p>
      {vmcData?.carts?.map((item, index) => {
        const isSelected = selectedCarts.some(
          (cart) => cart.pair === item.pair.key && cart.interval === item.time_frame
        );
        return (
          <button
            key={index}
            className={`px-2 disabled:cursor-not-allowed text-xs border rounded-lg ${
              isSelected ? "border-sky-40" : "border-storm-30"
            }`}
            onClick={() => handleCartClick(item, isSelected)}
            disabled={isLoading}
          >
            <Cart
              pair={item.pair}
              timeFrame={item.time_frame}
              breakCount={item.break_count}
              breakPoints={item.break_points[item.pair.key][item.time_frame]}
            />
          </button>
        );
      })}
    </>
  );
};
