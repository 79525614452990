import { setupApi } from "~/api/dashboard/setupApi";

export const setupFormValidation = (
  sumWeight,
  errorMessage,
  isLoading,
  setupValues,
  dataHasChanged
) => {
  const areAllPointsValid = (points) => {
    return ["ep", "tp", "sl"].every((type) =>
      points[type].every((point) => Number(point.value) && Number(point.weight))
    );
  };
  return (
    errorMessage?.points !== undefined ||
    (errorMessage?.pointsWeight &&
      Object.keys(errorMessage?.pointsWeight).filter(
        (key) => errorMessage?.pointsWeight[key]
      ).length > 0) ||
    Object.values(sumWeight).some((value) => value === 0) ||
    isLoading ||
    !setupValues.vault ||
    Object.values(setupValues.points).some((arr) => arr.length === 0) ||
    !areAllPointsValid(setupValues.points) ||
    !dataHasChanged
  );
};

export const createOrEditSetup = (
  id,
  parameters,
  setIsLoading,
  onSuccess,
  errorMessage,
  setErrorMessage
) => {
  setIsLoading(true);
  setErrorMessage(undefined);
  if (id) {
    setupApi
      .edit(id, parameters)
      .then((res) => {
        if (res && !res.error && res?.data) {
          onSuccess(res?.data?.data?.uuid);
        } else if (res?.error) {
          if (res?.error.response?.data?.errors) {
            setErrorMessage({
              ...errorMessage,
              create: res?.error?.response?.data.errors,
            });
          } else
            setErrorMessage({
              ...errorMessage,
              create: { error: [res?.error.response?.data.message] },
            });
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  } else
    setupApi
      .createNew(parameters)
      .then((res) => {
        if (res && !res.error && res?.data) {
          onSuccess();
        } else if (res?.error) {
          if (res?.error.response?.data?.errors) {
            setErrorMessage({
              ...errorMessage,
              create: res?.error?.response?.data.errors,
            });
          } else
            setErrorMessage({
              ...errorMessage,
              create: { error: [res?.error.response?.data.message] },
            });
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
};
