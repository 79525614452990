import { technicalApi } from "~/api/dashboard/analysis/technicalApi";

export const fetchTechnicalDetails = async (id, setDetails, setIsLoading) => {
  try {
    const result = await technicalApi.single(id);

    setDetails(result.data);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching pairs data:", error);
  }
};

export const fetchEditHistory = async (id, setHistoryDetails) => {
  try {
    const result = await technicalApi.editHistory(id);
    if (result?.data) setHistoryDetails({ data: result?.data, error: null });
    else if (result?.error)
      setHistoryDetails({ data: null, error: result?.error.message });
  } catch (error) {
    console.error("Error fetching pairs data:", error);
  }
};
