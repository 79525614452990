export const allPointsHaveValues = (points) => {
  return Object.values(points).every((arr) => {
    return arr.some((item) => item?.value?.trim() !== "");
  });
};

export const validatePriceConditions = (positionType, points) => {
  const omitInvalidValues = (points) => {
    return points
      .map((point) => parseFloat(point.value))
      .filter((value) => !isNaN(value));
  };

  const epPrices = omitInvalidValues(points.ep);
  const tpPrices = omitInvalidValues(points.tp);
  const slPrices = omitInvalidValues(points.sl);

  const isTpGreaterThanEp = tpPrices.every((tp) => epPrices.every((ep) => tp > ep));
  const isTpLessThanEp = tpPrices.every((tp) => epPrices.every((ep) => tp < ep));
  const isSlLessThanEp = slPrices.every((sl) => epPrices.every((ep) => sl < ep));
  const isSlGreaterThanEp = slPrices.every((sl) => epPrices.every((ep) => sl > ep));
  const isTpGreaterThanSl = tpPrices.every((tp) => slPrices.every((sl) => tp > sl));
  const isTpLessThanSl = tpPrices.every((tp) => slPrices.every((sl) => tp < sl));

  const pointsErrors = {};

  if (positionType === "long") {
    if (!isTpGreaterThanEp) pointsErrors.tp_ep = "TP points should be greater than EPs";
    if (!isSlLessThanEp) pointsErrors.sl_ep = "SL points should be less than EPs";
    if (!isTpGreaterThanSl) pointsErrors.tp_sl = "TP points should be greater than SLs";
  } else if (positionType === "short") {
    if (!isTpLessThanEp) pointsErrors.tp_ep = "TP points should be less than EPs";
    if (!isSlGreaterThanEp) pointsErrors.sl_ep = "SL points should be greater than EPs";
    if (!isTpLessThanSl) pointsErrors.tp_sl = "TP points should be less than SLs";
  }

  return pointsErrors;
};
