import React, { useState, useEffect } from "react";

import { Loading } from "../ui/Loading";
import { Node } from "./Node";

export const EditHistory = ({
  id,
  activeAnalysis,
  setActiveAnalysis,
  fetchEditHistory,
  historyDetails,
  uuidPath,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const getUuid = (child) => {
    try {
      return uuidPath.reduce((acc, key) => acc?.[key], child) || null;
    } catch {
      return null;
    }
  };

  useEffect(() => {
    fetchEditHistory(id);
  }, [id]);

  useEffect(() => {
    if (historyDetails?.data !== null || historyDetails?.error !== null)
      setIsLoading(false);
  }, [historyDetails]);

  return (
    <>
      {historyDetails?.data && !isLoading ? (
        <div>
          <div className="node flex-col gap-2 flex items-center relative w-full">
            <Node
              details={
                <button
                  className="text-sky-key font-roboto"
                  onClick={() => {
                    setActiveAnalysis(id);
                  }}
                >
                  {activeAnalysis === id ? "Showing" : "Show"}
                </button>
              }
              title="Current version"
              uuid={id}
              isActive={activeAnalysis === id}
            />
            {historyDetails?.data?.delete_log.length > 0 && (
              <>
                <Node
                  title={`${historyDetails?.data?.delete_log.length} previous version`}
                  isActive={null}
                />
                {historyDetails?.data?.delete_log.map((child, index) => {
                  const date = new Date(child.created_at);
                  const formattedDate =
                    date.toISOString().slice(0, 10) +
                    " - " +
                    date.toTimeString().slice(0, 8);
                  const uuid = getUuid(child);
                  const isActive = uuid === activeAnalysis;

                  return (
                    <Node
                      key={index}
                      title={formattedDate}
                      uuid={uuid}
                      isActive={isActive}
                      details={
                        <>
                          {isActive !== null && (
                            <button
                              className="text-sky-key font-roboto"
                              onClick={() => {
                                setActiveAnalysis(uuid);
                              }}
                            >
                              {isActive ? "Showing" : "Show"}
                            </button>
                          )}
                        </>
                      }
                      supportingElement={
                        <div className="flex flex-col gap-1 text-storm-40 text-xs">
                          <h6>Edited Items</h6>
                          <p className="font-roboto">
                            {child?.difference?.map((item, index) => (
                              <span key={index}>
                                {item}
                                {index !== child?.difference?.length - 1 && " . "}
                              </span>
                            ))}
                          </p>
                        </div>
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      ) : isLoading ? (
        <Loading size="small" />
      ) : (
        <p className="font-roboto text-sm text-storm-key">
          This analyse doesn't have any history.
        </p>
      )}
    </>
  );
};
