export const sortAscending = (array, key = "value") => {
  return array?.sort((a, b) => a[key] - b[key]);
};

export const sortDescending = (array, key = "value") => {
  return array?.sort((a, b) => b[key] - a[key]);
};

export const getState = (currentState) => {
  if (currentState.includes("Target")) {
    return "Target";
  } else if (currentState.includes("Stop")) {
    return "Stop";
  } else if (
    currentState.includes("Entry") ||
    currentState.includes("Terminate") ||
    currentState.includes("Terminate.")
  ) {
    return "Entry";
  } else {
    return "Cancel";
  }
};
