import React, { useState, useMemo, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { setupApi } from "~/api/dashboard/setupApi";

import Accordion from "~/components/child/ui/Accordion";
import { Loading } from "~/components/child/ui/Loading";
import Button from "~/components/child/ui/Button";
import { HeatmapDetails } from "~/components/child/heatmapDetails";
import { Form } from "./form";

import { useHeatMapDetails, useKeySubmission, useVaultsList } from "~/utils/hooks";
import { setupFormValidation, createOrEditSetup } from "./helper";

const CreateOrEditSetup = ({ permissions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setupId = searchParams.get("id");
  const heatmapId = searchParams.get("heatmapId");

  const {
    details,
    chartDetails,
    isLoading: heatMapDetailsIsLoading,
  } = useHeatMapDetails(heatmapId);

  const vaultsList = useVaultsList()?.data;

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [setupValues, setSetupValues] = useState({
    vault: undefined,
    points: {
      ep: [],
      tp: [],
      sl: [],
    },
    usersId: [],
    description: "",
    maxDrawDown: 0,
    maxProfit: 0,
    RR: 0,
    positionType: undefined,
  });

  const [sumWeight, setSumWeight] = useState({
    ep: 0,
    sl: 0,
    tp: 0,
  });

  const [dataHasChanged, setDataHasChanged] = useState(false);

  const fetchSetupDetails = async (id) => {
    const transformPoints = (array) =>
      array.map((item, index) => ({
        key: item.amount,
        value: parseInt(item.amount, 10),
        id: index,
        isClosed: item.is_closed,
        rr: item.rr ? item.rr : null,
        weight: Number(item.weight),
        editable: item.editable,
        user: item.user_uuid,
      }));

    try {
      const {
        data: { data },
      } = await setupApi.editSingle(id);
      setSetupValues({
        vault: {
          key: data.vault_uuid,
          value: vaultsList?.find((item) => item.uuid === data.vault_uuid)?.name,
        },
        points: {
          ep: transformPoints(data?.points?.ep),
          tp: transformPoints(data?.points?.tp),
          sl: transformPoints(data?.points?.sl),
        },
        description: data.description,
        maxDrawDown: data.mdd,
        RR: data.rr,
        maxProfit: data.max_profit,
        usersId: data.notifications[0]
          ? [
              ...data.notifications[0].receivers.map((user) => ({
                key: user.uuid,
                value: user.first_name + " " + user.last_name,
              })),
            ]
          : [],
        positionType: data.position_type,
      });
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (setupId && vaultsList?.length > 0) {
      fetchSetupDetails(setupId);
    }
  }, [setupId, vaultsList]);

  const heatmapDetailsProps = useMemo(
    () => ({
      id: heatmapId,
      selectedAnalyzers: details?.users ? [...details?.users] : [],
      details: details,
      chartDetails: chartDetails,
    }),
    [heatmapId, details, chartDetails]
  );

  const createOrEditParams = {
    heatmap_uuid: heatmapId,
    pair_id: details?.heatmap?.pair?.key,
    position_type: details?.heatmap?.technical.type[0].toLowerCase(),
    vault_uuid: setupValues?.vault?.key,
    notification_users: [...setupValues?.usersId?.map((user) => user.key)],
    mdd: setupValues.maxDrawDown,
    max_profit: setupValues.maxProfit,
    rr: setupValues.RR,
    description: setupValues.description,
    position_size: 1,
    points: Object.entries(setupValues.points).flatMap(([pointType, items]) => {
      return items.map((item) => ({
        point_type: pointType,
        amount: item.value,
        weight: item.weight,
        user_uuid: item.user,
        is_closed: item.isClosed,
        rr: item.rr,
      }));
    }),
  };

  useKeySubmission(
    () =>
      createOrEditSetup(
        setupId,
        createOrEditParams,
        setIsLoading,
        (id) => {
          if (setupId) navigate(`/setup?id=${id}`);
          else navigate("/setupList");
        },
        errorMessage,
        setErrorMessage
      ),
    () =>
      setupFormValidation(
        sumWeight,
        errorMessage,
        isLoading,
        setupValues,
        setupId && !dataHasChanged
      ),
    (event) => event.key === "Enter",
    []
  );

  const reviewChangesInData = () => {
    if (setupId && !dataHasChanged) setDataHasChanged(true);
  };

  return (
    <>
      {permissions?.full_access ? (
        <>
          {!heatMapDetailsIsLoading && details?.heatmap ? (
            <div className="main__router__container">
              <div className="main__router__container__view-box-container h-auto max-h-[85%]  w-full m-16  gap-2.5 flex flex-col scrollbar-style scrollbar-style-thumb">
                <>
                  <Form
                    heatmap={{ details: details?.heatmap, chart: chartDetails }}
                    setupValues={setupValues}
                    setSetupValues={setSetupValues}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    sumWeight={sumWeight}
                    setSumWeight={setSumWeight}
                    vaultsList={vaultsList}
                    isEditMode={setupId ?? false}
                    reviewChangesInData={setupId ? reviewChangesInData : undefined}
                  />
                  <div className="flex items-center w-full mt-6 gap-4">
                    <Button
                      onClick={() => {
                        if (setupId) navigate(`/setup?id=${setupId}`);
                        else navigate(`/heatmap/${heatmapId}`);
                      }}
                      className="w-1/2"
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={setupFormValidation(
                        sumWeight,
                        errorMessage,
                        isLoading,
                        setupValues,
                        setupId ? dataHasChanged : true
                      )}
                      className="w-1/2"
                      variant="primary"
                      isLoading={isLoading}
                      onClick={() => {
                        createOrEditSetup(
                          setupId,
                          createOrEditParams,
                          setIsLoading,
                          (id) => {
                            if (setupId) navigate(`/setup?id=${id}`);
                            else navigate("/setupList");
                          },
                          errorMessage,
                          setErrorMessage
                        );
                      }}
                    >
                      {setupId ? "Save Changes" : "Submit"}
                    </Button>
                  </div>
                  {errorMessage &&
                  errorMessage?.create &&
                  Object.values(errorMessage?.create).length > 0 ? (
                    Object.values(errorMessage?.create).flatMap((error) => (
                      <p className="mt-2 font-roboto text-sm text-infrared-key">
                        {error}
                      </p>
                    ))
                  ) : (
                    <p className="mt-2 font-roboto text-sm text-infrared-key">
                      {errorMessage?.create?.error[0]}
                    </p>
                  )}
                </>
                {!setupId && (
                  <div className="flex p-8 flex-col bg-base rounded-3xl">
                    <Accordion
                      className={{ root: "p-0" }}
                      label={{
                        leftPart: (
                          <h3 className="font-robotoMed text-sky-70 text-lg">
                            Heat map Details
                          </h3>
                        ),
                      }}
                      openDefault={true}
                    >
                      <div className="">
                        <hr className="border-black opacity-10 w-full" />
                        <HeatmapDetails
                          {...heatmapDetailsProps}
                          mode="setup"
                          className="py-4"
                        />
                      </div>
                    </Accordion>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Loading size="large" />
          )}
        </>
      ) : (
        permissions !== undefined && (
          <p className="my-[50%] mx-auto text-infrared-key">
            You don't have permission to add new analysis.
          </p>
        )
      )}
    </>
  );
};

export default CreateOrEditSetup;
